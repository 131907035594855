import React from "react"

import { contactData } from "./content"

import Contact from "../Contact"
import Image from "../Image"

import { CheckCircle } from "@styled-icons/fa-solid/CheckCircle";
import {
  PanelWrapper,
  PanelSlogan,
  PanelShadow,
  PanelContent,
  ImageWrapper,
  InnerContainer,
  Slogan,
  OptionTitle,
  Options,
  OptionContainer,
  OptionItem,
} from "./styles"

export default function ContactPage() {
  const { title, contentTitle, optionTitle, options, contactTitle } = contactData

  return (
    <PanelWrapper>
      <ImageWrapper>
        <Image filename="contato.png"/>
      </ImageWrapper>
      <PanelContent>
        <InnerContainer>
          <PanelSlogan>{title}</PanelSlogan>
          <Contact name="contact-page"/>
        </InnerContainer>
        <InnerContainer>
          <OptionTitle>{optionTitle}</OptionTitle>
          <Options>
            {options.map((item, idx) => (
              <OptionContainer key={idx}>
                <CheckCircle color="#8CC63F" size={30} />
                <OptionItem>{item}</OptionItem>
              </OptionContainer>
            ))}
          </Options>
        </InnerContainer>
      </PanelContent>
      <PanelShadow />
    </PanelWrapper>
  )
}

