export const contactData = {
  title: "Relacionamento de profissional para profissional",
  optionTitle: "Um serviço mais que personalizado",
  options: [
    "+ Que uma contabilidade",
    "+ Que uma assessoria",
    "+ Que análises",
  ],
  contactTitle: "A sua Personal Accountant",
}
