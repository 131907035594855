import React from "react";

import SEO from "../components/seo";
import ContactPage from "../components/ContactPage";

import Layout from "../components/Layout";

const Contato = () => (
  <Layout>
    <SEO title="Contato" />
    <ContactPage />
  </Layout>
);

export default Contato;
